<template>
    <v-app>
      <v-container>
        <v-card class="mx-auto my-10" max-width="344" elevation="12">
          <v-img height="250" src="../assets/RB-nontransparant-logo1.webp"></v-img>
          <v-card-title class="text-center">Bekräftelse</v-card-title>
          <v-card-text class="text-center">
            <strong v-if="message">{{ message }}</strong>
            <v-alert v-else type="error">Bekräftelse misslyckades. Vänligen försök igen.</v-alert>
          </v-card-text>
  
          <v-card-actions class="justify-center">
            <v-btn color="#383e42" class="white--text" @click="goToLogin" v-if="message">
              Till inloggning
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-app>
  </template>
  
  <script>
  export default {
    name: "EmailConfirmation",
    data() {
      return {
        message: "",
      };
    },
    methods: {
      async confirmEmail() {
        const token = this.$route.query.token; // Get the token from the URL
        if (token) {
          try {
            const response = await this.$http.get(`/api/UserCustomer/confirm-email?token=${token}`);
            this.message = response.data.message;
          } catch (error) {
            console.error("Email confirmation failed:", error);
          }
        }
      },
      goToLogin() {
        this.$router.push("/").catch(() => {});
      },
    },
    mounted() {
      this.confirmEmail();
    },
  };
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }
  </style>
  